import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/atoms/seo";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import SectionFold from "../components/organisms/section-fold";

const IndexPage = (props) => {
  const generalData = props.data?.generalData;
  const pageData = props.data?.pageData?.edges[0]?.node;
  const pagesData = props.data?.pagesData?.edges;

  return (
    <Layout>
      <Seo title={pageData.pageTitle} />
      <Header generalData={generalData} pagesData={pagesData} />
      <main>
        <SectionFold pageData={pageData} bgColor="bg-white" showScrollTo={false} />
      </main>
      <Footer generalData={generalData} pagesData={pagesData} />
    </Layout>
  );
};

export const query = graphql`
  query {
    generalData: contentfulGeneralSettings {
      footerEmail
      footerPhoneNumber
      logo {
        fluid(maxWidth: 284, maxHeight: 160) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    pagesData: allContentfulPage {
      edges {
        node {
          slug
          pageName
          id
          hideInNavigation
          hideInFooter
          navigationOrder
        }
      }
    }
    pageData: allContentfulPage(filter: { slug: { eq: "/" } }) {
      edges {
        node {
          id
          slug
          pageTitle
          description {
            raw
          }
          callToActions {
            id
            buttonText
            buttonVariant
            target {
              ... on ContentfulCareerOpportunities {
                slug
              }
              ... on ContentfulPage {
                slug
              }
            }
            textVariant
          }
          pageHeaderImage {
            fluid(maxWidth: 1920, quality: 10) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
